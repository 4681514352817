@charset "UTF-8";

@import "../mixin";

#wp_contents {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
    font-weight: 700;
    margin: 30px 0 15px;
    color: $colorBrown;
  }

  h1 {
    font-size: 22px;
    line-height: 1.2308;
    @include sp {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  h2 {
    font-size: 21px;
    line-height: 1.2069;
  }

  h3 {
    font-size: 20px;
    line-height: 1.1667;
  }

  h4 {
    font-size: 19px;
    line-height: 1.4;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 17px;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }

  p {
    margin: 0 0 28px;
    line-height: 1.8;
    @include sp {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  dfn,
  cite,
  em,
  i {
    font-style: italic;
  }

  blockquote {
    border-left: 4px solid #707070;
    color: #707070;
    font-size: 20px;
    font-style: italic;
    line-height: 1.8182;
    margin: 0 0 35px -21px;
    padding-left: 17px;
  }

  blockquote > blockquote {
    margin-left: 0;
  }

  blockquote p {
    margin-bottom: 35px;
  }

  blockquote > p:last-child {
    margin-bottom: 0;
  }

  blockquote cite,
  blockquote small {
    color: #333;
    font-family: "Noto Sans", sans-serif;
    font-size: 17px;
    line-height: 1.6471;
  }

  blockquote em,
  blockquote i,
  blockquote cite {
    font-style: normal;
  }

  blockquote strong,
  blockquote b {
    font-weight: 400;
  }

  address {
    font-style: italic;
    margin: 0 0 28px;
  }

  code,
  kbd,
  tt,
  var,
  samp,
  pre {
    font-family: Inconsolata, monospace;
  }

  pre {
    background-color: #fcfcfc;
    border: 1px solid #eaeaea;
    font-size: 17px;
    line-height: 1.2353;
    margin-bottom: 28px;
    max-width: 100%;
    overflow: auto;
    padding: 14px;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  abbr[title] {
    border-bottom: 1px dotted #eaeaea;
    cursor: help;
  }

  mark,
  ins {
    background-color: #fff9c0;
    text-decoration: none;
  }

  sup,
  sub {
    font-size: 75%;
    height: 0;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    bottom: 1ex;
  }

  sub {
    top: .5ex;
  }

  small {
    font-size: 75%;
  }

  big {
    font-size: 125%;
  }

  /**
   * 3.0 Elements
   */

  hr {
    background-color: #eaeaea;
    border: 0;
    height: 1px;
    margin-bottom: 28px;
  }

  ul,
  ol {
    margin: 0 0 28px 0;
    padding: 0;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li > ul,
  li > ol {
    margin: 0 0 0 23px;
  }

  blockquote > ul,
  blockquote > ol {
    margin-left: 28px;
  }

  dl {
    margin: 0 0 28px;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0 0 28px;
  }

  table,
  th,
  td,
  .mce-item-table,
  .mce-item-table th,
  .mce-item-table td {
    border: 1px solid #eaeaea;
  }

  table a {
    color: #333;
  }

  table,
  .mce-item-table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 28px;
    width: 100%;
  }

  table th,
  .mce-item-table th,
  table caption {
    border-width: 0 1px 1px 0;
    font-family: "Noto Serif", serif;
    font-size: 17px;
    font-weight: 700;
    padding: 7px;
    text-align: left;
    vertical-align: baseline;
  }

  table td,
  .mce-item-table td {
    border-width: 0 1px 1px 0;
    font-family: "Noto Serif", serif;
    font-size: 17px;
    padding: 7px;
    vertical-align: baseline;
  }

  img {
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }

  figure {
    margin: 0;
  }

  del {
    opacity: 0.8;
  }

  a {
    border-bottom: 1px solid #333;
    color: #333;
    text-decoration: none;
  }

  /**
   * 4.0 Alignment
   */

  .alignleft {
    float: left;
    margin: 7px 28px 28px 0;
  }

  .alignright {
    float: right;
    margin: 7px 0 28px 28px;
  }

  .aligncenter {
    clear: both;
    display: block;
    margin: 7px auto;
  }

  /**
   * 5.0 Caption
   */

  .wp-caption {
    background: transparent;
    border: none;
    color: #707070;
    font-family: "Noto Sans", sans-serif;
    margin: 0 0 28px 0;
    max-width: 100%;
    padding: 0;
    text-align: inherit;
  }

  .wp-caption.alignleft {
    margin: 7px 28px 21px 0;
  }

  .wp-caption.alignright {
    margin: 7px 0 21px 28px;
  }

  .wp-caption.aligncenter {
    margin: 7px auto;
  }

  .wp-caption .wp-caption-text,
  .wp-caption-dd {
    font-size: 14px;
    line-height: 1.5;
    padding: 7px 0;
  }

  /**
   * 6.0 Galleries
   */

  .gallery-item {
    display: inline-block;
    padding: 1.79104477%;
    text-align: center;
    vertical-align: top;
    width: 100%;
  }

  .gallery-columns-2 .gallery-item {
    max-width: 50%;
  }

  .gallery-columns-3 .gallery-item {
    max-width: 33.33%;
  }

  .gallery-columns-4 .gallery-item {
    max-width: 25%;
  }

  .gallery-columns-5 .gallery-item {
    max-width: 20%;
  }

  .gallery-columns-6 .gallery-item {
    max-width: 16.66%;
  }

  .gallery-columns-7 .gallery-item {
    max-width: 14.28%;
  }

  .gallery-columns-8 .gallery-item {
    max-width: 12.5%;
  }

  .gallery-columns-9 .gallery-item {
    max-width: 11.11%;
  }

  .gallery .gallery-caption {
    color: #707070;
    display: block;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    padding: 7px 0;
  }

  .gallery-columns-6 .gallery-caption,
  .gallery-columns-7 .gallery-caption,
  .gallery-columns-8 .gallery-caption,
  .gallery-columns-9 .gallery-caption {
    display: none;
  }

  /**
   * 7.0 Audio / Video
   */

  .mce-content-body .wpview-wrap {
    margin-bottom: 32px;
  }

  .mce-content-body .wp-audio-playlist {
    margin: 0;
  }

  /**
   * 8.0 RTL
   */

  body.rtl {
    font-family: Arial, Tahoma, sans-serif;
  }

  .rtl blockquote {
    border-left: none;
    border-right: 4px solid #707070;
    margin: 0 -21px 35px 0;
    padding-left: 0;
    padding-right: 17px;
  }

  .rtl blockquote > blockquote {
    margin-left: auto;
    margin-right: 0;
  }

  .rtl li > ul,
  .rtl li > ol {
    margin: 0 23px 0 0;
  }

  .rtl table th,
  .rtl table caption {
    text-align: right;
  }

}